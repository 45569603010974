type TProps = {
  link: string;
  text: string;
}[];

export const HEADER_NAV: TProps = [
  {link: '/', text: 'Browse Listings'},
  {link: 'https://www.offerwell.com/#feature', text: 'Features'},
  {link: 'https://www.offerwell.com/#about-us', text: 'About Us'},
  {link: '/contact-us', text: 'Contact Us'},
];

export const LOGGED_HEADER_NAV: TProps = [];
