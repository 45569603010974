export type TPaymentProps = {
  type: 'Visa' | 'Master';
  name: string;
  expires: string;
  end_of: string;
  isDefault: boolean;
};
export type TTableProps = {
  file: string;
  date: string;
  detail: string;
  price: string;
};

export const NOTIF_SELECT = ['Email', 'SMS', 'SMS & Email'];

export const PAYMMENT_CARDS: TPaymentProps[] = [
  {
    type: 'Visa',
    name: 'Visa',
    expires: '12/27',
    end_of: '1234',
    isDefault: true,
  },
  {
    type: 'Master',
    name: 'Mastercard',
    expires: '12/27',
    end_of: '1234',
    isDefault: false,
  },
];
export const TABLE: TTableProps[] = [
  {
    file: 'http://www.segerman.org/tshirts/peano-gosper_curve.png',
    date: '12/06/2022',
    detail: '112 Whitney Pl, Buffalo, NY 14201',
    price: '5.00',
  },
  {
    file: 'http://www.segerman.org/tshirts/peano-gosper_curve.png',
    date: '12/06/2022',
    detail: '112 Whitney Pl, Buffalo, NY 14201',
    price: '5.00',
  },
  {
    file: 'http://www.segerman.org/tshirts/peano-gosper_curve.png',
    date: '12/06/2022',
    detail: '112 Whitney Pl, Buffalo, NY 14201',
    price: '5.00',
  },
  {
    file: 'http://www.segerman.org/tshirts/peano-gosper_curve.png',
    date: '12/06/2022',
    detail: '112 Whitney Pl, Buffalo, NY 14201',
    price: '5.00',
  },
];
