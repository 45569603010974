import {Offer, OfferFields} from '@/features/offers/types';
import {NewBrand} from '@/themes/palletes/types';

type TProps = {
  color: keyof NewBrand;
  title: string;
  isTimer?: boolean;
};
export type TOfferTableProps = {
  title: string;
  value?: string;
  id: string;
};
export type TRowsProps = {
  value: keyof OfferFields;
  is_detail: boolean;
  id: string;
};
export type TFieldProps = {
  title: string;
  text: string;
};
export type THalfpageTableProps = {
  title: string;
  value: keyof OfferFields;
  is_money?: boolean;
  percent?: boolean;
  is_inspection?: boolean;
  date?: boolean;
  time?: boolean;
};

export type TCounterProps = {
  field: keyof Offer;
  title: string;
};
export const CARD_INFO: TProps[] = [
  {title: 'Submitted', color: 'monochrome_5'},
  {
    title: 'Highest Offer',
    color: 'success_0',
  },
  {
    title: 'Lowest Offer',
    color: 'danger_0',
  },
  {
    title: 'Offer Deadline',
    color: 'monochrome_5',
    isTimer: true,
  },
];

export const TABLE_COLUMS: TOfferTableProps[] = [
  {title: 'Agent Name', value: '11/14/2022 12:48 PM', id: 'Item 10'},
  // {title: 'Status', value: '$200,000', id: 'Item 11'},
  {title: 'Date/Time Added', value: '11/14/2022 12:48 PM', id: 'Item 0'},
  {title: 'Offer Amount', value: '$200,000', id: 'Item 1'},
  {title: 'Concessions', value: '$0', id: 'Item 2'},
  {title: 'Escalation Clause', value: '$212,000', id: 'Item 3'},
  {title: 'Net Offer Price', value: '77', id: 'Item 4'},

  {title: 'Deposit Amount', value: '$212,000', id: 'Item 5'},
  {title: 'Inspection', value: '77', id: 'Item 6'},
  {title: 'Contingency', value: '77', id: 'Item 7'},
  {title: 'Gift Funds', value: '77', id: 'Item 8'},
  {title: 'Financing Type', value: '77', id: 'Item 9'},
  {
    title: 'Lender',
    id: 'Item 12',
  },
  {
    title: 'Appliances Included',
    id: 'Item 13',
  },
  {
    title: 'Mortage Commitment Date',
    id: 'Item 14',
  },
  {
    title: 'Loan amount not to exceed',
    id: 'Item 15',
  },
  {
    title: 'Interest rate not to exceed',
    id: 'Item 16',
  },
  {
    title: 'Closing Date',
    id: 'Item 17',
  },
  {
    title: 'Down payment',
    id: 'Item 18',
  },

  {
    title: 'Possession Date',
    id: 'Item 19',
  },
  {
    title: 'Possession Days',
    id: 'Item 20',
  },
  {
    title: 'Possession Time',
    id: 'Item 21',
  },
  {
    title: 'Appraisal Contingency',
    id: 'Item 22',
  },
  {
    title: 'Septic Escrow',
    id: 'Item 23',
  },
  {
    title: 'Septic Escrow Amount',
    id: 'Item 24',
  },
  {
    title: 'Home Warranty',
    id: 'Item 25',
  },
  {
    title: 'Home Warranty Paid By',
    id: 'Item 26',
  },
  {
    title: 'Home Warranty Company',
    id: 'Item 27',
  },
  {
    title: 'Home Warranty Amount',
    id: 'Item 28',
  },
  {
    title: 'Title Prepared By',
    id: 'Item 29',
  },
];
export const TABLE_ROWS: TRowsProps[] = [
  {value: 'buyer_first_name_1', is_detail: false, id: 'Item 10'},
  {value: 'status', is_detail: false, id: 'Item 11'},
  {value: 'date_created', is_detail: false, id: 'Item 0'},
  {
    is_detail: true,
    value: 'purchase_price',
    id: 'Item 1',
  },
  {
    is_detail: true,
    value: 'concessions',
    id: 'Item 2',
  },
  {
    is_detail: true,
    value: 'calculated_escalation_amount',
    id: 'Item 3',
  },
  {
    is_detail: true,
    value: 'net_to_seller',
    id: 'Item 4',
  },
  {
    is_detail: true,
    value: 'deposit_amount',
    id: 'Item 5',
  },
  {
    is_detail: true,
    value: 'inspection_days',
    id: 'Item 6',
  },
  {
    is_detail: true,
    value: 'sale_contingency',
    id: 'Item 7',
  },
  {is_detail: true, value: 'gift_fund', id: 'Item 8'},
  {
    is_detail: true,
    value: 'type_of_finance_display',
    id: 'Item 9',
  },
  {
    is_detail: true,
    value: 'lender',
    id: 'Item 12',
  },
  {
    is_detail: true,
    value: 'appliances',
    id: 'Item 13',
  },
  {
    is_detail: true,
    value: 'mortgage_commitment_date',
    id: 'Item 14',
  },
  {
    is_detail: true,
    value: 'down_payment',
    id: 'Item 15',
  },
  {
    is_detail: true,
    value: 'interest_rate_not_exceed',
    id: 'Item 16',
  },
  {
    is_detail: true,
    value: 'closing_date',
    id: 'Item 17',
  },
  {
    is_detail: true,
    value: 'down_payment',
    id: 'Item 18',
  },

  {
    is_detail: true,
    value: 'possession_date',
    id: 'Item 19',
  },
  {
    is_detail: true,
    value: 'possession_days',
    id: 'Item 20',
  },
  {
    is_detail: true,
    value: 'possession_time',
    id: 'Item 21',
  },
  {
    is_detail: true,
    value: 'appraisal_contingency',
    id: 'Item 22',
  },
  {
    is_detail: true,
    value: 'septic_escrow',
    id: 'Item 23',
  },
  {
    is_detail: true,
    value: 'septic_escrow_amount',
    id: 'Item 24',
  },
  {
    is_detail: true,
    value: 'home_warranty',
    id: 'Item 25',
  },
  {
    is_detail: true,
    value: 'home_warranty_paid_by',
    id: 'Item 26',
  },
  {
    is_detail: true,
    value: 'home_warranty_company',
    id: 'Item 27',
  },
  {
    is_detail: true,
    value: 'home_warranty_amount',
    id: 'Item 28',
  },
  {
    is_detail: true,
    value: 'title_prepared_by',
    id: 'Item 29',
  },
];

export const COUNTER_FIELDS: TCounterProps[] = [
  {field: 'purchase_price', title: 'Offer amount'},
  {field: 'concessions', title: 'Concessions'},
  {field: 'deposit_amount', title: 'Deposit amount'},
  {field: 'appliances', title: 'Appliances Included'},
  {field: 'appliances_detail', title: 'Appliances'},
  {field: 'inspection_days', title: 'Inspection days'},
  {field: 'inspection', title: 'Inspection'},
  {field: 'appliances_detail', title: 'Appliances'},
  {field: 'mortgage_commitment_date', title: 'Mortage commitment date'},
  {
    field: 'interest_rate_not_exceed',
    title: 'Interest rate not to exceed',
  },
  {field: 'down_payment', title: 'Loan amount not to exceed'},
  {field: 'closing_date', title: 'Closing date'},
  {field: 'additional_terms', title: 'Additional terms'},
];

export const COUNTER_BUYER_FIELDS: TCounterProps[] = [
  {field: 'additional_terms', title: 'Additional terms'},
  {field: 'closing_date', title: 'Closing date'},
  {field: 'concessions', title: 'Concessions'},
  {field: 'deposit_amount', title: 'Deposit amount'},
  {field: 'inspection_days', title: 'Inspection days'},
  {field: 'inspection', title: 'Inspection'},
  {field: 'purchase_price', title: 'Offer amount'},
  {field: 'escalation', title: 'Escalation clause'},
  {field: 'increment', title: 'Escalation increment'},
  {field: 'max_escalation', title: 'Escalation max price'},
  {field: 'sale_contingency', title: 'Contingency'},
  {field: 'closing_contingency', title: 'Closing contingency'},
  {field: 'gift_fund', title: 'Gift funds'},
  {field: 'type_of_finance', title: 'Financing type'},
  {field: 'lender', title: 'Lender'},
  {field: 'down_payment', title: 'Loan amount not to exceed'},
  {field: 'interest_rate_not_exceed', title: 'Interest rate not to exceed'},
  {field: 'appliances', title: 'Appliances Included'},
  {field: 'appliances_detail', title: 'Appliances'},
  {field: 'mortgage_commitment_date', title: 'Mortage commitment'},
  {field: 'other_contingency', title: 'Other contingency'},
  {field: 'liable_contingency', title: 'Liable contingency'},
  {field: 'closing_address', title: 'Closing address'},
  {field: 'loan_term', title: 'Loan term'},
];

export const COUNTER_HISTORY_TABLE: THalfpageTableProps[] = [
  {value: 'purchase_price', title: 'Offer Amount', is_money: true},
  {value: 'concessions', title: 'Concessions', is_money: true},
  {value: 'inspection_days', title: 'Inspection', is_inspection: true},
  {value: 'deposit_amount', title: 'Deposit Amount', is_money: true},
  {value: 'sale_contingency', title: 'Sale Contingency'},
  {value: 'closing_contingency', title: 'Closing Contingency'},
  {value: 'closing_address', title: 'Closing Address'},
  {value: 'gift_fund', title: 'Gift Funds'},
  {value: 'type_of_finance', title: 'Financing Type'},
  {value: 'lender', title: 'Lender'},
  {
    value: 'interest_rate_not_exceed',
    title: 'Interest rate not to exceed',
    percent: true,
  },
  {value: 'appliances_detail', title: 'Appliances Included'},
  {
    value: 'mortgage_commitment_date',
    title: 'Mortage Commitment Date',
    date: true,
  },
  {value: 'closing_date', title: 'Closing Date', date: true},
  {value: 'additional_terms', title: 'Other Terms'},
];

export const OFFER_TABLE: THalfpageTableProps[] = [
  {value: 'purchase_price', title: 'Offer Amount', is_money: true},
  {
    value: 'max_escalation',
    title: 'Escalation Clause',
    is_money: true,
  },
  {value: 'concessions', title: 'Concessions', is_money: true},
  {value: 'inspection_days', title: 'Inspection', is_inspection: true},
  {value: 'deposit_amount', title: 'Deposit Amount', is_money: true},
  {value: 'sale_contingency', title: 'Sale Contingency'},
  {value: 'closing_contingency', title: 'Closing Contingency'},
  {value: 'closing_address', title: 'Closing Address'},
  {value: 'gift_fund', title: 'Gift Funds'},
  {value: 'down_payment', title: 'Loan Amount Not To Exceed', percent: true},
  {value: 'type_of_finance_display', title: 'Financing Type'},
  {value: 'lender', title: 'Lender'},
  {
    value: 'interest_rate_not_exceed',
    title: 'Interest rate not to exceed',
    percent: true,
  },
  {value: 'appliances_detail', title: 'Appliances Included'},
  {
    value: 'mortgage_commitment_date',
    title: 'Mortage Commitment Date',
    date: true,
  },
  {value: 'closing_date', title: 'Closing Date', date: true},
  {value: 'additional_terms', title: 'Other Terms'},
  {value: 'title_prepared_by', title: 'Title Prepared By'},
  {value: 'possession_date', title: 'Possession Date', date: true},
  {value: 'possession_days', title: 'Possession Days'},
  {value: 'possession_time', title: 'Possession Time', time: true},
  {value: 'appraisal_contingency', title: 'Appraisal Contingency'},
  {value: 'septic_escrow', title: 'Septic Escrow'},
  {
    value: 'septic_escrow_amount',
    title: 'Septic Escrow Amount',
    is_money: true,
  },
  {value: 'home_warranty_paid_by', title: 'Home Warranty Paid By'},
  {value: 'home_warranty_company', title: 'Home Warranty Company'},
  {
    value: 'home_warranty_amount',
    title: 'Home Warranty Amount',
    is_money: true,
  },
];

export const MINI_COUNTER_TABLE: THalfpageTableProps[] = [
  {value: 'closing_date', title: 'Closing Date', date: true},
  {value: 'concessions', title: 'Concessions', is_money: true},
  {value: 'deposit_amount', title: 'Deposit Amount', is_money: true},
  {value: 'purchase_price', title: 'Offer Amount', is_money: true},

  {value: 'appliances', title: 'Appliances Included'},
  {value: 'appliances_detail', title: 'Appliances'},
  {value: 'inspection_days', title: 'Inspection days', is_inspection: true},
  {value: 'inspection', title: 'Inspection'},
  {
    value: 'mortgage_commitment_date',
    title: 'Mortage commitment date',
    date: true,
  },
  {
    value: 'interest_rate_not_exceed',
    title: 'Interest rate not to exceed',
    percent: true,
  },
  {value: 'down_payment', title: 'Loan amount not to exceed', percent: true},
  {value: 'additional_terms', title: 'Additional terms'},
];

export const PREVIEW_TABLE: THalfpageTableProps[] = [
  {value: 'purchase_price', title: 'Offer Amount', is_money: true},
  {
    value: 'calculated_escalation_amount',
    title: 'Escalation Clause',
    is_money: true,
  },
  {value: 'increment', title: 'Escalation Increment', is_money: true},
  {value: 'max_escalation', title: 'Escalation Max Price', is_money: true},
  {value: 'concessions', title: 'Concessions', is_money: true},
  {value: 'inspection_days', title: 'Inspection', is_inspection: true},
  {value: 'deposit_amount', title: 'Deposit Amount', is_money: true},
  {value: 'sale_contingency', title: 'Sale Contingency'},
  {value: 'closing_contingency', title: 'Closing Contingency'},
  {value: 'closing_address', title: 'Closing Address'},

  {value: 'gift_fund', title: 'Gift Funds'},
  {value: 'down_payment', title: 'Loan Amount Not To Exceed', percent: true},
  {value: 'type_of_finance', title: 'Financing Type'},
  {value: 'lender', title: 'Lender'},
  {
    value: 'interest_rate_not_exceed',
    title: 'Interest rate not to exceed',
    percent: true,
  },
  {value: 'appliances_detail', title: 'Appliances Included'},
  {
    value: 'mortgage_commitment_date',
    title: 'Mortage Commitment Date',
    date: true,
  },
  {value: 'closing_date', title: 'Closing Date', date: true},
  {value: 'additional_terms', title: 'Other Terms'},
];
