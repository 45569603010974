const routes = {
  AUTH_ROUTER: '/auth/*', //NEXT migrated replaced
  MAIN_ROUTER: '/agent/*',

  //AUTH
  SIGN_IN: 'signIn', //NEXT migrated replaced
  SIGN_UP: 'signUp', //NEXT migrated replaced

  SET_UP: 'set-up', //NEXT migrated replaced

  FORGOT_PASSWORD: 'forgot-password', //NEXT unused
  RESET_PASSWORD: 'reset-password', //NEXT unused

  //HOME
  HOME: '/', //NEXT unused
  ABOUT_US: '/about', //NEXT unused
  FEEDBACK: '/feedback', //NEXT migrated partial

  //AUTH
  TO_SIGN_IN: '/auth/signIn', //NEXT migrated replaced
  TO_SIGN_UP: '/auth/signUp', //NEXT migrated replaced

  TO_SET_UP: '/set-up', //NEXT unused

  TO_FORGOT_PASSWORD: '/auth/forgot-password', //NEXT unused

  TO_RESET_PASSWORD: '/auth/reset-password', //NEXT unused
  //MAIN
  DASHBOARD: 'dashboard',
  TO_DASHBOARD: '/agent/dashboard',

  START_OFFER: 'start-offer',
  TO_START_OFFER: '/start-offer',

  CREATE_OFFER: 'listing/:id/offer/:offer_id',
  TO_CREATE_OFFER: '/listing/:id/offer/:offer_id',

  SCHEDULE_SHOWING: 'schedule-showing/:id',

  AGENT_LISTING: 'listing/:id',

  LISTING_SETUP: 'listing/:id/setup/',

  EDIT_LISTING: 'listing/:id/edit',

  TO_SETTINGS: '/agent/settings',

  PROFILE: 'agents/:agent_slug',

  SELLER_DASHBOARD: 'listing/:id/dashboard',

  OFFER_VIEW: 'listing/:id/offer/:offer_id/view',

  ARCHIVE: 'archive',
  TO_ARCHIVE: '/agent/archive',

  TO_ALL_LISTING: '/',

  ALL_LISTING_VIEW: 'listings/:id',

  NOTIFICATIONS: 'notifications',
  //CLIENT

  OCCUPANT_SHOWINGS: 'occupant-showings/:uuid/:code',
} as const;

export default routes;
