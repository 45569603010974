export const FORM_LABELS = {
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email address',
  PHONE_NUMBER: 'Phone number',
  BUYER_ADDRESS: 'Buyer’s address',
  CLOSING_DATE: 'Closing date',
  DOCUMENT_TYPE: 'Select document type',
  ACCEPT_CONTRACT: 'Accepts only PDF',
  ACCEPT_IMG: 'Accepts .png and .jpeg,',
  ACCEPT_PDF_DOC: 'Accepts .png, .jpeg, and .pdf',
  ROLE_AND_PERMISSIONS: 'Role & permissions',
  PASSWORD: 'Password',
  VERIFICATION_CODE: 'Verification code',
  OLD_PASSWORD: 'Old password',
  NEW_PASSWORD: 'New password',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
  LICENSE_NUMBER: 'License number',
};

export const FORM_ERRORS = {
  FIELD_REQUIRED: 'Field required.',
  EMAIL_VALID: 'Please enter valid email address',
  PHONE_VALID: 'Please enter a valid phone number',
  FIRST_NAME_REQUIRED: 'Please enter your First Name',
  PHONE_REQUIRED: 'Please enter a phone number',
  LAST_NAME_REQUIRED: 'Please enter your Last Name',
  ADDRESS: 'Please enter the address',
  ROLE_AND_PERMISSIONS_REQUIRED: 'Please enter a valid role and permissions',
  EMAIL_REQUIRED: 'Please enter your email',
  NO_MATCH_PASSWORD: 'Passwords must match',
  MIN_LENGTH_PASSWORD: 'Password must be at least 8 characters long',
};
