import slide_1 from '@/assets/images/Homepage/slider_1.svg';
import slide_3 from '@/assets/images/Homepage/slider_3.svg';
import slide_4 from '@/assets/images/Homepage/slider_4.svg';
import slide_5 from '@/assets/images/Homepage/slider_5.svg';
import slide_6 from '@/assets/images/Homepage/slider_6.svg';

type TProps = {
  title: string;
  text: string;
  img: string;
}[];

export const HOME_SLIDER: TProps = [
  {
    title: 'Offer Dashboard',
    text: 'View all of your offers in one place on the Avvie offer dashboard. No more needing to find offers in your inbox, they simple appear once they are submitted, and we even take care of calculating escalation clause and your net seller amount',
    img: slide_1,
  },

  {
    title: 'Cool Dashboard',
    text: 'View all of your offers in one place on the Avvie offer dashboard. No more needing to find offers in your inbox, they simple appear once they are submitted, and we even take care of calculating escalation clause and your net seller amount',
    img: slide_3,
  },
  {
    title: 'Hight Dashboard',
    text: 'View all of your offers in one place on the Avvie offer dashboard. No more needing to find offers in your inbox, they simple appear once they are submitted, and we even take care of calculating escalation clause and your net seller amount',

    img: slide_4,
  },
  {
    title: 'Width Dashboard',
    text: 'View all of your offers in one place on the Avvie offer dashboard. No more needing to find offers in your inbox, they simple appear once they are submitted, and we even take care of calculating escalation clause and your net seller amount',
    img: slide_5,
  },
  {
    title: 'Fine Dashboard',
    text: 'View all of your offers in one place on the Avvie offer dashboard. No more needing to find offers in your inbox, they simple appear once they are submitted, and we even take care of calculating escalation clause and your net seller amount',
    img: slide_6,
  },
];
