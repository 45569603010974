import routes from '@/router/routes';

type TProps = {
  text: string;
  href: string;
}[];

export const FOOTER_NAV: TProps[] = [
  [
    {text: 'Support', href: ''},
    {text: 'Contact Us', href: ''},
    {text: 'Give Us Your Feedback', href: ''},
    {text: 'View Site Map', href: ''},
  ],
  [
    {text: 'About', href: routes.ABOUT_US},
    {text: 'FAQs', href: ''},
    {text: 'Avvie For Agents', href: ''},
    {text: 'Avvie For Consumers', href: ''},
  ],
];
export const LOGGED_FOOTER_NAV: TProps = [
  {text: 'Privacy Policy', href: routes.ABOUT_US},
  {text: 'Terms Of Use', href: ''},
  {text: 'FAQs', href: ''},
  {text: 'Give Us Your Feedback', href: ''},
  {text: 'Contact Us', href: ''},
];
